import React from 'react';
import { GetKoala } from './src/analytics/GetKoala';
import { AnalyticsGoogle } from './src/analytics/Google';
import { AnalyticsFacebook } from './src/analytics/Facebook';
import { AnalyticsLinkedin } from './src/analytics/Linkedin';
import { AnalyticsLeadfeeder } from './src/analytics/Leadfeeder';
import { isProduction } from './src/utils/isEnv';
import { ModalLoadable } from './src/components/Modal';

// eslint-disable-next-line
export default ({ element }) => {
  const production = isProduction();

  return (
    <>
      {element}
      <ModalLoadable />
      {production && (
        <>
          <AnalyticsGoogle />
          <AnalyticsFacebook />
          <AnalyticsLinkedin />
          <AnalyticsLeadfeeder />
          <GetKoala />
        </>
      )}
    </>
  );
};
