import React from 'react';
import { Script, ScriptStrategy } from 'gatsby';

export const GetKoala = () => {
  return (
    <>
      <Script strategy={ScriptStrategy.postHydrate}>
        {`
          !function(t)
          {var k="ko",i=(window.globalKoalaKey=window.globalKoalaKey||k);
          if(window[i])return;var ko=(window[i]=[]);["identify","track","removeListeners","on","off","qualify","ready"].forEach(function(t){ko[t]=function()
          {var n=[].slice.call(arguments);return n.unshift(t),ko.push(n),ko}});
          var n=document.createElement("script");
          n.async=!0,n.setAttribute("src","https://cdn.getkoala.com/v1/pk_32e5862e13ee068ce7e19c00c4d65d7accba/sdk.js"),(document.body || document.head).appendChild(n)}();
        `}
      </Script>
    </>
  );
};
